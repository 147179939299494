const SERVICES =
[
  {
    service_id: 0,
    img_source: '/static/oil-8a9e9df976a8e51797f1474905bd16f3.png',
    service_type: 'Relaxation',
    description: 'A smooth, gentle, flowing stroke style that promotes over-all relaxation.  It relieves muscular tension, increases range of motion, as well as, improves  circulation.',
    subtype_one: 'Half Body',
    price_one: '$45',
    subtype_two: 'Full Body',
    price_two: '$77',
    subtype_three: '90 Minutes',
    price_three: '$110',
    subtype_four: '2 Hours',
    price_four: '$144',
  },
  {
    service_id: 1,
    img_source: '/static/deep-tissue-a56a5216831010f82d6969e3351aaf5d.jpg',
    service_type: 'Deep Tissue',
    description: 'The use of slow strokes, muscle stretching and deep finger pressure to relieve tension from deep muscles layers and connective tissues. Also, helping to ease chronic muscle pain and anxiety.',
    subtype_one: 'Half Body',
    price_one: '$55',
    subtype_two: 'Full Body',
    price_two: '$95',
    subtype_three: '',
    price_three: '',
    subtype_four: '',
    price_four: '',
  },
  {
    service_id: 2,
    img_source: '/static/lymphatic-771fe38ee8af92956bf817de79d0754c.jpg',
    service_type: 'Lymphatic Drainage',
    description: 'Uses a gentle, rhythmic massage treatment meant to stimulate the circulation of lymph fluid through the body. Helps to speed up the removal of wastes and toxins from your lymphatic system, and can aid in the prevention of swelling after injury or surgery.',
    subtype_one: 'Half Body',
    price_one: '$50',
    subtype_two: 'Full Body',
    price_two: '$100',
    subtype_three: '',
    price_three: '',
    subtype_four: '',
    price_four: '',
  },
  {
    service_id: 3,
    img_source: '/static/stone-5fd56ded398b07fd54ca65fd12acf3cd.jpg',
    service_type: 'Hot Stone',
    description: 'Heated stones are used to massage different parts of the body using Swedish massage techniques with gentle pressure. Hot stones ease muscle tension, assist in improving blood flow/circulation, relieving pain, while promoting relaxation.',
    subtype_one: 'Half Body',
    price_one: '$60',
    subtype_two: 'Full Body',
    price_two: '$120',
    subtype_three: '',
    price_three: '',
    subtype_four: '',
    price_four: '',
  },
  {
    service_id: 4,
    img_source: '/static/back-1c901428eca2a4c00112bc38c11fef97.png',
    service_type: 'Body Section',
    description: 'Focused treatment on a specific area of your body. Body section massages are available for your back, neck, arms, legs, hands and feet.',
    subtype_one: 'Session length per client request',
    price_one: '$1.50 per minute (15 minute minimum)',
    subtype_two: '',
    price_two: '',
    subtype_three: '',
    price_three: '',
    subtype_four: '',
    price_four: '',
  },
  {
    service_id: 5,
    img_source: '/static/chair_massage_2-3b4d3298246376e25e0c9e3ee99a4008.jpg',
    service_type: 'Chair Massage',
    description: 'A short massage 15 to 30 mins while fully clothed with the focus on the neck, shoulders and back.',
    subtype_one: '15 Minutes',
    price_one: '$20',
    subtype_two: '20 Minutes',
    price_two: '$25',
    subtype_three: '30 Minutes',
    price_three: '$35',
    subtype_four: '',
    price_four: '',
  },
  {
    service_id: 6,
    img_source: '/static/clinical-22eb0a7a155e859d2cc9070d51c1e89c.jpg',
    service_type: 'Clinical / Medical Massage',
    description: 'Prescribed and intended to resolve conditions that have been diagnosed/prescribed by a physician or medical professional. Session modality use depends on the diagnoses and body section affected.',
    subtype_one: 'Contact Office for Pricing',
    price_one: '',
    subtype_two: '',
    price_two: '',
    subtype_three: '',
    price_three: '',
    subtype_four: '',
    price_four: '',
  },
  {
    service_id: 7,
    img_source: '/static/cancer_massage_3-11aa09202f096cba5109401bd29fadae.jpg',
    service_type: 'Massages for Cancer Patients',
    description: 'An oncology massage is a client-specific, customized massage session designed to meet the unique and changing needs of someone in treatment for cancer or with a history of cancer treatment. A safe massage plan generally revolves around the side effects (both short and long-term) of chemotherapy, radiation and surgery.',
    subtype_one: 'Contact Office for Pricing',
    price_one: '',
    subtype_two: '',
    price_two: '',
    subtype_three: '',
    price_three: '',
    subtype_four: '',
    price_four: '',
  },
  {
    service_id: 8,
    img_source: '/static/corporate-b5ba4885bb12cb918e05e5cdb9071b60.jpg',
    service_type: 'Corporate Massages',
    description: 'Corporate Massage is typically a 15 minute treatment given to employees at the workplace. Most commonly treatments are focused on the head, neck, shoulders and upper back. Employees remain fully clothed and no oils or lotions are used.',
    subtype_one: 'Contact Office for Pricing',
    price_one: '',
    subtype_two: '',
    price_two: '',
    subtype_three: '',
    price_three: '',
    subtype_four: '',
    price_four: '',
  },
];

export default SERVICES;
