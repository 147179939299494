import React from "react"
import { Link } from 'gatsby'
import Layout from '../components/Layout'
import SERVICES from '../data/services'
import Service from '../components/Service'
import SEO from '../components/SEO'

import ncbtmb from "../img/ncbtmb_logo.png"
import s4om from "../img/s40m_logo.png"
import biofreeze from "../img/biofreezelogo.png"

const Services = props => {
  return(
    <Layout>
    <SEO
      title="Services"
    />
      <div className='padding-content-section alert-dismissed'>
        <h3 className="responsive-title">Services</h3>
        <div className="service-table">
        {
          SERVICES.map(service =>(
            <Service
              service_id = {service.service_id}
              source = {service.img_source}
              description = {service.description}
              service_type = {service.service_type}
              subtype_one = {service.subtype_one}
              subtype_two = {service.subtype_two}
              subtype_three = {service.subtype_three}
              subtype_four = {service.subtype_four}
              price_one = {service.price_one}
              price_two = {service.price_two}
              price_three = {service.price_three}
              price_four = {service.price_four}
            />
          ))
        }
        </div>
        <p className="disclaimer"><strong>Note:&nbsp;</strong>all appointments are subject to a $25 non-cancellation fee.</p>
        <h4 className="responsive-title">Ready to take the next step?</h4>
        <Link to="/contact" aria-label="Request an Appointment"><button className="button button-primary">Request an Appointment</button></Link>
      </div>

      <div id="payments" className="tertiary padding-content-section">
        <h3 className="responsive-title">Paying for your massage</h3>
        <p>
          mBody Health is dedicated to providing you with the highest quality, cost effective massage therapy care.
          We accept all major credit cards, checks and cash payments.
        </p>
        <h5>Paying through insurance</h5>
          <p>
            Depending on your eligibility of coverage, mBody health will accept insurance from certain providers.
            Prior to beginning treatment, patients must provide proof of coverage for massage therapy services.
            Proof of coverage for massage therapy services may take the form of a letter of coverage from your insurance provider, or a letter from your attorney confirming such coverage.
            mBody Health is a provider for the Bureau of Worker's Compensation (BWC). 
          </p>
        <Link to="/contact" aria-label="Contact us"><button className="button button-secondary">Still have questions? Contact us for more information</button></Link>
      </div>
      <div id="payments" className="padding-content-section">
        <h3 className="responsive-title">Helpful Links</h3>
        <a href="https://www.linkedin.com/in/louise-mercer-a1bb1049/" target="_blank" className="helpful-link" rel="noopener noreferrer">A Touch of Denmark MT</a>
        <a href="https://www.biofreeze.com/" target="_blank" className="helpful-link" rel="noopener noreferrer"><img alt="Biofreeze logo" src={biofreeze} style={{height: "2rem", marginBottom: "1rem"}}/></a>
        <a href="https://www.s4om.org/" target="_blank" className="helpful-link" rel="noopener noreferrer"><img alt="Society for Oncology Massage Logo" src={s4om} style={{height: "3rem", marginBottom: "1rem"}}/></a>
        <a href="https://www.ncbtmb.org/" target="_blank" className="helpful-link" rel="noopener noreferrer"><img alt="NCBTMB Logo" src={ncbtmb} style={{height: "10rem"}}/></a>
      </div>
    </Layout>

  )

}

export default Services;
