import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'

const Service = props => {
  const data = useStaticQuery(query);
  function getNodeFromSource(){
    let list = data.allImageSharp.nodes
    for (var i = 0; i < list.length; i++){
        if (list[i].original.src === props.source){
          return i;
        }
      }
    }
  return(
    <div key={props.service_id} className="service-container">
      <div className="service-cell dark">
        <div>
          <Img
          fluid={data.allImageSharp.nodes[getNodeFromSource()].fluid}
          objectFit="cover"
          objectPosition="50% 50%"
          className="service-card-image"
          />
        </div>
      <div className="service-content">
        <div>
          <h5>{props.service_type}</h5>
          <p className="">{props.description}</p>
        </div>
        <div className="pricing">
          <p>{props.subtype_one}</p>
          <p>{props.price_one}</p>
        </div>
        <div className="pricing">
          <p>{props.subtype_two}</p>
          <p>{props.price_two}</p>
        </div>
        <div className="pricing">
          <p>{props.subtype_three}</p>
          <p>{props.price_three}</p>
        </div>
        <div className="pricing">
          <p>{props.subtype_four}</p>
          <p>{props.price_four}</p>
        </div>
      </div>
      <Link to="/contact" aria-label="Request an Appointment"><button className="button-left button-primary">Request an Appointment&nbsp;&rarr;</button></Link>
      </div>
    </div>

  )
}

export default Service;

const query = graphql`
  query{
    allImageSharp{
      nodes {
        original {
          width
          height
          src
        }
        fluid{
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
  `
